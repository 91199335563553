import React from "react";
import Map from "./Map";
import blogImage from "../images/img-blood-donation.jpg";
const EventsDetailBlood = () => {
  return (
    <section className="event-detail-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="blog-content">
              <div className="blog-item">
                <div className="blog-img">
                  <img src={blogImage} alt="" />
                  <span className="blog__tag">
                    <span className="date__num-text">9</span>
                    <span className="date__mon-text">mar</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="event-detail-content">
              <div className="event-detail-item">
                <h3 className="event__title">Play for the world</h3>
                <p className="event__text">
                  Blood donation is a noble act that can save lives. The NGO
                  “Purba Dhansara Jote Jana Kalyan Samity” understands this and
                  organized a blood donation camp to help those in need. The
                  camp was set up in a convenient location and was open to all
                  willing donors.
                </p>
                <p className="event__text">
                  The NGO made sure that the process of donating blood was safe
                  and hygienic. All necessary precautions were taken to ensure
                  the well-being of the donors. The blood collected at the camp
                  will be used to help those who require blood transfusions.
                </p>
              </div>
              <div className="event-detail-item">
                <h3 className="event__title event__title2">
                  Event Requirements
                </h3>
                <p className="event__text">
                  The event was a huge success, with many people coming forward
                  to donate blood. The NGO has expressed its gratitude to all
                  those who came forward to donate and has encouraged more
                  people to do so in the future. Blood donation is a simple act
                  that can make a big difference in someone’s life.
                </p>
              </div>
              <div className="event-detail-btn">
                <a href="#none" className="theme-btn">
                  register now
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="event-detail-sidebar">
              <div className="event-detail-item">
                <h3 className="event__title">Event Details</h3>
                <ul className="event__list">
                  <li>
                    <span>Starting Time:</span> 8:00AM to 2:00PM
                  </li>
                  <li>
                    <span>Date:</span>9 March, 2019
                  </li>
                  <li>
                    <span>Category:</span>Health
                  </li>
                  <li>
                    <span>Phone:</span>+91-9933086710
                  </li>
                  <li>
                    <span>Website:</span>info@pdjjks.org
                  </li>
                  <li>
                    <span>Location:</span>Siliguri, West Bengal, India
                  </li>
                </ul>
              </div>
              <div className="event-detail-item event-detail-item2">
                <Map />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventsDetailBlood;
