import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import EventsDetailBlood from "../components/EventsDetailBlood";

const BloodDonation = () => {
  return (
    <Layout pageTitle="PDJJKS | Blood Donation Event Detail">
      <NavOne />
      <PageHeader title="Blood Donation" />
      <EventsDetailBlood />
      <Footer />
    </Layout>
  );
};

export default BloodDonation;
